import DefaultPage from 'legacy/pages/DefaultPage'
import $ from 'legacy/vendor/jquery.custom'
import { oneLine } from 'common-tags'

import { GAReporter, GAEvents } from 'lib/GoogleAnalytics'
import Browser from 'legacy/lib/Browser'
import Cookies from 'legacy/lib/Cookies'

import ListingCardCarousel from 'legacy/views/carousels/ListingCardCarousel'

export default class HomePage extends DefaultPage {
  static childComponents = [
    ...super.childComponents,

    ListingCardCarousel,
  ]

  static async initialize() {
    await super.initialize()

    // Focus search input if we’re on desktop and not scrolled down
    if (!Browser.isMobile && !window.scrollY) $('#search-location').focus()

    this.showRecentSearch()

    $('.js-homepage-referral-form').on('submit', (event) => {
      event.preventDefault()

      GAReporter.trackEvent(GAEvents.CONNECT_WITH_AN_AGENT.SUBMIT, {
        eventLabel: 'via-homepage-inline-form',
      })

      this.onReferralRequest(event)
    })
  }

  static onReferralRequest = async (event) => {
    const $form         = $(event.currentTarget)
    const $errors       = $form.find('.js-referral-request-errors')
    const $submitButton = $form.find('[type=submit]')

    $submitButton.prop('disabled', true)
    $errors.hide()

    try {
      await $.post($form.prop('action'), $form.serialize())
    } catch (xhr) {
      $submitButton.prop('disabled', false)
      return void $errors.html(this.formattedErrors(xhr)).show()
    }

    $('.js-hide-from-users-after-request').remove()
    $('.js-show-to-users-after-request').removeClass('hidden')
  }

  static formattedErrors(xhr) {
    try {
      const { errors } = xhr.responseJSON

      if (xhr.status === 500) throw new Error('Server error')
      if (!errors)            throw new Error('Response has no errors object')

      return oneLine`
        <ul class="list-unstyled">
          ${Object.values(errors).map(e => `<li>${e}</li>`).join('')}
        </ul>
      `
    } catch (e) {
      return oneLine`
        We were unable to process your request.
        If you continue to have problems, you can contact us via phone
        by calling us at <a href="tel:+18003418685">1-800-341-8685</a>.
        We apologize for the inconvenience.
      `
    }
  }

  static showRecentSearch() {
    const lastSearch = Cookies.get('permalinkv2')

    if (!lastSearch?.search_path) return

    const $recentSearch = $('.js-recent-search')
    const label = lastSearch.search_label?.replace(/\+/g, ' ') // decodeURIComponent doesn’t change "+" to space.

    $recentSearch.find('a').attr('href', lastSearch.search_path)
    $recentSearch.removeClass('invisible')

    if (label) {
      $recentSearch.find('.js-search-label').text(label)
      $recentSearch.find('.js-search-with-label').removeClass('hidden')
    } else {
      $recentSearch.find('.js-search-without-label').removeClass('hidden')
    }
  }
}
